// src/ProfileCard.js
import React from 'react';

const ProfileCard = ({ profile }) => {
    let followers = 'N/A';
    let likes = 'N/A';
    let restStats = [];

    if (profile.StatsBreakdown) {
        const statsLines = profile.StatsBreakdown.split('\n');
        try {
            followers = statsLines[0].match(/\d+\w*/)[0];
            likes = statsLines[1].match(/\d+\w*/)[0];
            restStats = statsLines.slice(2);
        } catch (error) {
            console.error('Error parsing stats:', error);
        }
    }

    const mailtoLink = `mailto:${profile.Email}?subject=${encodeURIComponent(profile.EmailSubject)}&body=${encodeURIComponent(profile.EmailBody)}`;

    const radius = 28;
    const circumference = 2 * Math.PI * radius;
    const offset = (1 - profile.Rating / 100) * circumference;

    if (profile.Rating == null) {
        profile.Rating = -1;
    }

    let color;
    if (profile.Rating > 90) {
        color = '#00FF00'; // bright green
    } else if (profile.Rating > 80) {
        color = '#32CD32'; // lime green
    } else if (profile.Rating > 70) {
        color = '#98FB98'; // pale green
    } else if (profile.Rating > 60) {
        color = '#ADFF2F'; // yellow-green
    } else if (profile.Rating > 30) {
        color = '#FFC107'; // yellow
    } else {
        color = '#F44336'; // red
    }

    const removeMarkdown = (text) => {
        return text.replace(/\*\*/g, '');
    };

    return (
        <div className="flex flex-col w-full bg-[#14161A] rounded-[2rem] py-7 px-4 gap-3 sm:w-3/4 sm:px-7 xl:w-1/2">
            <header className="flex flex-col gap-3">
                <div className="flex flex-row items-center w-full justify-between">
                    <img
                        src={`https://tiktokprofilepics.s3.us-east-2.amazonaws.com/${profile.Profile}.png`}
                        alt=""
                        loading="lazy"
                        className="h-16 w-16 sm:w-28 sm:h-28 rounded-full"
                        onError={(e) => e.target.src = '/Images/avatar.png'}
                    />
                    <div className="flex flex-row items-center gap-3">
                        <div className="relative flex justify-center items-center h-14 w-14">
                            {profile.Rating !== -1 ? (
                                <div className="relative flex justify-center items-center h-14 w-14">
                                    <svg className="absolute" width="60" height="60">
                                        <circle
                                            cx="30"
                                            cy="30"
                                            r={radius}
                                            stroke={color}
                                            strokeWidth="3"
                                            fill="transparent"
                                            strokeDasharray={circumference}
                                            strokeDashoffset={offset}
                                            strokeLinecap="butt"
                                            transform="rotate(90 30 30)"
                                        />
                                    </svg>
                                    <p className="text-white text-sm absolute">
                                        {profile.Rating}
                                        <span className="-ml-1">%</span>
                                    </p>
                                </div>
                            ) : (
                                <div className="relative flex justify-center items-center h-14 w-14 border border-2 border-white rounded-full">
                                    <p className="text-white text-sm">N/A</p>
                                </div>
                            )}
                        </div>
                        <p className="text-white text-sm">Fit</p>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center items-start gap-2 sm:gap-0 w-full">
                    <h4 className="text-white font-medium text-2xl mt-2">{profile.Profile}</h4>
                    <a className="text-[#D1D5DB] text-sm underline text-left sm:text-right w-full sm:w-auto" href={`mailto:${profile.Email}`}>
                        {profile.Email}
                    </a>
                </div>

                <p className="text-[#9CA3AF] text-base text-left">{removeMarkdown(profile.Description)}</p>
            </header>

            <div className="grid grid-cols-1 grid-rows-1 gap-4 w-full mt-4 sm:grid-cols-2">
                <div className="flex flex-col gap-2 py-4 px-6 pb-6 border border-1 border-[#1F2937] rounded-2xl w-full text-left">
                    <div className="flex flex-row gap-2">
                        <img src="/Images/nimbus_stats.svg" alt="" className="h-full" />
                        <h5 className="text-[#E5E7EB] font-medium">STATS</h5>
                    </div>

                    <div className="flex flex-col gap-1 text-left mt-2">
                        <div className="flex flex-row justify-start gap-2">
                            <span className="text-[#E5E7EB] font-medium">{followers}</span>
                            <span className="text-[#9CA3AF]">Followers</span>
                            <span className="text-[#E5E7EB] font-medium pl-4">{likes}</span>
                            <span className="text-[#9CA3AF]">Likes</span>
                        </div>
                        {restStats.map((stat, index) => {
                            const [value, ...text] = stat.split(' ');
                            return (
                                <p key={index} className="text-[#9CA3AF]">
                                    <span className="text-[#E5E7EB] font-medium">{value}</span> {text.join(' ')}
                                </p>
                            );
                        })}
                    </div>
                </div>

                <div className="flex flex-col gap-2 py-4 px-6 pb-6 border border-1 border-[#1F2937] rounded-2xl w-full text-left">
                    <div className="flex flex-row gap-2">
                        <img src="/Images/Vector.svg" alt="" className="h-full" />
                        <h5 className="text-[#E5E7EB] font-medium">DEMOGRAPHICS</h5>
                    </div>

                    <p className="text-[#9CA3AF] mt-2">{removeMarkdown(profile.Demographics)}</p>
                </div>

                <div className="flex flex-col gap-2 py-4 px-6 pb-6 border border-1 border-[#1F2937] rounded-2xl w-full col-span-full text-left">
                    <div className="flex flex-row gap-2 items-center">
                        <img src="/Images/academicons_ideas-repec.svg" alt="" className="h-full" />
                        <h5 className="text-[#E5E7EB] font-medium">CONTENT INTEGRATION IDEAS</h5>
                    </div>

                    <ul className="list-disc pl-5 text-[#9CA3AF] mt-1">
                        {profile.ContentIntegrationIdeas.split('\n').map((idea, index) => (
                            <li key={index} className="text-[#9CA3AF] custom-list-item">
                                {removeMarkdown(idea.replace(/^[-•]\s*/, ''))}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="grid grid-rows-1 grid-cols-1 gap-4 sm:grid-cols-2 w-full mt-2 col-span-full">
                    <a
                        href={`https://www.tiktok.com/@${profile.Profile}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-row items-center justify-center bg-[#F9FAFB] gap-2 rounded-3xl p-3 text-[#1F2937] w-full"
                    >
                        Visit Profile
                        <img src="/Images/heroicons_link.svg" alt="" />
                    </a>

                    <a
                        href={mailtoLink}
                        className="flex flex-row items-center justify-center bg-[#F9FAFB] gap-2 rounded-3xl p-3 text-[#1F2937] w-full"
                    >
                        Send Custom Email
                        <img src="/Images/mage_email.svg" alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
