import React from 'react';
import './App.css';
import { useEffect, useState } from 'react';
import ProfileCard from './ProfileCard';

function App() {
  const [profiles, setProfiles] = useState([]);
  // Assuming your basePath is "/final-report"
const loadingGif = '/tAp4jVTlJedVO0CqwX3gMnMUE.gif';
const [reportTitle, setReportTitle] = useState('Influencer Marketing Report');
const [error, setError] = useState(false);  // State to handle errors
  useEffect(() => {
    // This code now safely runs on client side only
    //const queryParams = new URLSearchParams(window.location.search);
    //const sessionID = queryParams.get('SessionID');
    //const userID = queryParams.get('UserID');
    const path = window.location.pathname.substring(1);
    //console.log(path);

    // Validate the path length (should be 33 characters: 1 for runType, 32 for token)
    if (path.length !== 7) {
      setError(true)
        console.error('Invalid URL format. The URL must include a single character for runType followed by a 32-character token.');
        return;
    }

    // Separate the runType and token
    const runType = path.charAt(0); // First character is runType
    const token = path.substring(1); // Next 32 characters are the token
    //let runType = queryParams.get('RunType');

    // Check if any required parameter is missing and handle it
    if (!token || !runType) {
      setError(true);
      return; // Exit the effect early if parameters are missing
    }

    /*
    if (runType === 'F') {
      setReportTitle('Sample Report');
    }
    */
    const fetchProfiles = async () => {
      // Make sure to replace 'API_ENDPOINT_URL' with the actual endpoint of your Lambda function
      const lambdaUrl = 'https://757lxuxsunivjgtmfywwuwhivu0kjhoa.lambda-url.us-east-2.on.aws/';
      const response = await fetch(lambdaUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify({ SessionID: sessionID, UserID: userID, RunType : runType}),
        body: JSON.stringify({ Token : token, RunType : runType}),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setProfiles(data);
        if(data.length === 0)
          setError(true);
      } else {
        // Handle HTTP errors
        setError(true);
        console.error('HTTP error:', response.status);
      }
    };

    // Ensure sessionID and userID are defined before fetching
    if (token && runType) {
      //console.log(token);
      fetchProfiles();
    }
  }, []);

  if(error)
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',  // This sets the height of the div to be the full height of the viewport
      fontSize: '1.5rem',  // Equivalent to text-1xl in Tailwind CSS
      fontWeight: 'normal',  // Makes the text bold
      textAlign: 'center',  // Centers the text horizontally
      borderTop: '2px solid',  // Applies a top border with a thickness of 2px
      color: 'white'  // Sets the font color to white
    }}>
      404 Page Not Found <br />Ensure the link is correct and try again
    </div>
  );
  //<img src={loadingGif} className="resize-image" />
  if (!error && profiles.length === 0) {
    return(
      <section className="load_section">
      <div className="sxd"><p className='loading_p'>Loading</p><div className="loading-dots">
      <span></span>
      <span></span>
      <span></span>
      </div>
      </div>
      </section>
      
      )
  }

  return (
    <div className="App">
      {error ? (
        <div>Error fetching profiles. Please check the URL and try again.</div>
      ) : (
        <div className="flex flex-col gap-10 w-[100dvw] items-center px-[0.5rem] py-10">
          
          <a href="https://spencer.jobs.chat/" className="flex items-center justify-start text-[#E5E7EB] text-md py-4 w-full ml-5 sm:ml-20">
  <img src="/Images/arrow.svg" alt="" className="h-4 mr-2" />
  <span className="pl-2">Go to Homepage</span>
</a>

  
          <div className="w-full flex flex-col bg-[#14161A] rounded-[2rem] py-7 px-4 gap-3 sm:w-3/4 sm:flex-row sm:gap-5 sm:px-7 xl:w-1/2">
            <img
              src="Images/0_0-6 1.png"
              alt="Spencer AI image"
              className="w-28 h-28 sm:w-36 sm:h-36 mx-auto sm:mx-0"
            />
  
            <div className="flex flex-col items-center gap-6 sm:items-start">
              <p className="text-[#E5E7EB] text-lg text-left">
                Hey, I just generated you this report! Thanks for the patience, if you have any problems with the results,
                please email my human representative at <span style={{ color: '#87AED1' }}>human@jobs.support</span>
              </p>
              <button onClick={() => downloadCSV(profiles)} className="flex flex-row items-center justify-center bg-[#F9FAFB] gap-2 rounded-3xl p-3 text-[#1F2937] w-full sm:w-fit sm:px-16">
                Export as CSV
              </button>
            </div>
          </div>
  
          {profiles.map((profile, index) => (
            <ProfileCard key={index} profile={profile} />
          ))}
        </div>
      )}
    </div>
  );
}

function convertJsonToCSV(jsonData) {
  const csvRows = [];
  // Get the headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push('"' + headers.join('","') + '"');  // Quote headers and join with commas

  // Loop over the rows
  for (const row of jsonData) {
      const values = headers.map(header => {
          let value = row[header] || '';  // Handle null or undefined
          value = value.replace(/"/g, '""');  // Escape existing double quotes by doubling them
          value = value.replace(/(\r\n|\n|\r)/gm, " ");  // Replace newlines with space to prevent breaking CSV format
          return `"${value}"`;  // Ensure each value is enclosed in double quotes
      });
      csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
}


function downloadCSV(profiles) {
  const csvData = convertJsonToCSV(profiles);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'influencer_report.csv');
  a.click();
  window.URL.revokeObjectURL(url);
}

export default App;